// -----------In code Include Only Logo------------------------------------------

import React from 'react';
import './menu.css';
import logo from '../images/NammaChitraLogo.png'; // Correct path for logo import

const Menu = () => {
    return (
        <header className="navbar">
            <div className="navbar-container">

                <div className="logo">
                    <img src={logo} alt="Logo" />
                </div>

            </div>
        </header>
    );
};

export default Menu;

// -----------In code Include Main Heading- And In Mobile No Toggle menu-----------------------------------------

// import React from 'react';
// import './menu.css';
// import logo from '../images/NammaChitraLogoBgR.png';

// const Menu = () => {
//     return (
//         <header className="navbar">
//             <div className="logo">
//                 <img
//                     src={logo}
//                     alt="Logo"
//                 />
//             </div>
//             <nav className="nav-menu">
//                 <ul>
//                     <li><a href="#">HOME</a></li>
//                     <li><a href="#">MOVIES</a></li>
//                     <li><a href="#">EVENTS</a></li>
//                     <li><a href="#">SPORTS</a></li>
//                     <li><a href="#">CONTACT</a></li>
//                 </ul>
//             </nav>

//         </header>
//     );
// };

// export default Menu;

// -------In Code For Toggle Menu------------------------------

// import React, { useState } from 'react';
// import './menu.css';
// import logo from '../images/NammaChitraLogoBgR.png';

// const Menu = () => {
//     const [isOpen, setIsOpen] = useState(false);

//     const toggleMenu = () => {
//         setIsOpen(!isOpen);
//     };

//     return (
//         <header className="navbar">
//             <div className="logo">
//                 <img
//                     src={logo}
//                     alt="Logo"
//                 />
//             </div>
//             <nav className={`nav-menu ${isOpen ? 'open' : ''}`}>
//                 <ul>
//                     <li><a href="#">HOME</a></li>
//                     <li><a href="#">MOVIES</a></li>
//                     <li><a href="#">EVENTS</a></li>
//                     <li><a href="#">SPORTS</a></li>
//                     <li><a href="#">CONTACT</a></li>
//                 </ul>
//             </nav>
//             <div className="hamburger" onClick={toggleMenu}>
//                 <span className="bar"></span>
//                 <span className="bar"></span>
//                 <span className="bar"></span>
//             </div>
//         </header>
//     );
// };

// export default Menu;


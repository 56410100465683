import React, { useState } from 'react';
import QrScanner from 'react-qr-scanner';
import './Scanqr.css';
import accountbg from '../images/account-bg.jpg';
import Swal from 'sweetalert2'; // Import SweetAlert

const Scanqr = () => {
    const [isScannerVisible, setIsScannerVisible] = useState(false);
    const [scanResult, setScanResult] = useState('');
    const [isSweetAlertOpen, setIsSweetAlertOpen] = useState(false); 

    const handleScanClick = () => {
        setIsScannerVisible(true); // Show the scanner
    };

    const handleScan = async (data) => {
        if (data && data.text) { // Check if `data.text` exists
            const scannedSerialNumber = data.text;
            setScanResult(scannedSerialNumber); // Set the scanned QR data (text part)
            setIsScannerVisible(false); // Hide scanner after successful scan

            // Send scanned data (serial number) to the backend for verification
            try {
                const response = await fetch('http://localhost:3000/api/verify-qr', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ serialNumber: scannedSerialNumber }),
                });

                const result = await response.json();

                // Set SweetAlert state to open
                setIsSweetAlertOpen(true);

                if (result.success) {
                    // Show success message using SweetAlert
                    Swal.fire({
                        icon: 'success',
                        title: 'QR Code Verified Successfully!',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'custom-confirm-button',
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            icon: 'custom-swal-icon',
                        },
                    }).then(() => {
                        // Set SweetAlert state to closed after alert is dismissed
                        setIsSweetAlertOpen(false);
                    });
                } else {
                    // Show error message using SweetAlert
                    Swal.fire({
                        icon: 'error',
                        title: 'Invalid QR Code',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'custom-confirm-button',
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            icon: 'custom-swal-icon',
                        },
                    }).then(() => {
                        // Set SweetAlert state to closed after alert is dismissed
                        setIsSweetAlertOpen(false);
                    });
                }
            } catch (error) {
                console.error('Error verifying QR code:', error);
                // Show error message using SweetAlert
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'custom-confirm-button',
                        popup: 'custom-swal-popup',
                        title: 'custom-swal-title',
                        icon: 'custom-swal-icon',
                    },
                }).then(() => {
                    // Set SweetAlert state to closed after alert is dismissed
                    setIsSweetAlertOpen(false);
                });
            }
        }
    };

    const handleError = (err) => {
        console.error(err);
        // Show error message using SweetAlert
        Swal.fire({
            icon: 'error',
            title: 'Scanner Error',
            text: 'An error occurred while scanning the QR code.',
            confirmButtonText: 'OK',
        }).then(() => {
            setIsSweetAlertOpen(false); // Close SweetAlert after error message
        });
    };

    return (
        <>


            <div className="qr-container" style={{ backgroundImage: `url(${accountbg})` }}>
                {!isSweetAlertOpen && (
                    <div className="qr-code-box">
                        {!isScannerVisible ? (
                            <>
                                <div className="join-us-button">
                                    <input
                                        type="button"
                                        className="btn"
                                        value="Scan QR Code"
                                        onClick={handleScanClick}
                                    />
                                </div>
                            </>
                        ) : (
                            <div className="scanner-camera">
                                <QrScanner
                                    delay={300}
                                    onError={handleError}
                                    onScan={handleScan}
                                    facingMode="environment" // Ensures the back camera is used
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>

        </>
    );
};

export default Scanqr;

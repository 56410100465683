import React from 'react';
import './NoticeModal.css';

const NoticeModal = ({ isVisible, onClose, onAccept }) => {
    if (!isVisible) return null;

    return (
        <div className="notice-modal-overlay">
            <div className="notice-modal">
                {/* Close Button */}
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <h2>Please Note</h2>
                <ul className="notice-list">
                    <li><span className="point-text">Only one pass would be allotted per phone number.<br />ಪ್ರತಿ ಫೋನ್ ಸಂಖ್ಯೆಗೆ ಕೇವಲ ಒಂದು ಪಾಸ್ ಅನ್ನು ಮಾತ್ರ ಹಂಚಲಾಗುತ್ತದೆ</span></li>
                    <li><span className="point-text">Limited seats and seats will be allotted at the venue on a first-come, first-served basis.<br />ಸೀಮಿತ ಆಸನಗಳು ಮಾತ್ರ ಇರುವುದರಿಂದ, ಸ್ಥಳದಲ್ಲೆ ಆಸನಗಳನ್ನು ಮೊದಲು ಬಂದವರಿಗೆ ಆದ್ಯತೆಯ ಆಧಾರದ ಮೇಲೆ ಹಂಚಲಾಗುತ್ತದೆ</span></li>
                    <li><span className="point-text">The pass is valid only for one day. You can enter only one day using this pass.<br />ಪಾಸ್ ಒಂದು ದಿನಕ್ಕೆ ಮಾತ್ರ ಮಾನ್ಯವಾಗಿರುತ್ತದೆ. ಈ ಪಾಸ್ ಬಳಸಿ ನೀವು ಕೇವಲ ಒಂದು ದಿನ ಮಾತ್ರ ನಮೂದಿಸಬಹುದು</span></li>
                    <li><span className="point-text">If you exit the event during the event, the QR code will not work for re-entry.<br />ಈವೆಂಟ್ ಸಮಯದಲ್ಲಿ ನೀವು ಈವೆಂಟ್‌ನಿಂದ ನಿರ್ಗಮಿಸಿದರೆ, ಮರು-ಪ್ರವೇಶಕ್ಕಾಗಿ QR ಕೋಡ್ ಕಾರ್ಯನಿರ್ವಹಿಸುವುದಿಲ್ಲ</span></li>
                    <li><span className="point-text">Right of admission is reserved with the management of NammaChitra.<br />ಪ್ರವೇಶದ ಹಕ್ಕನ್ನು ನಮ್ಮಚಿತ್ರದ ನಿರ್ವಹಣೆಯೊಂದಿಗೆ ಕಾಯ್ದಿರಿಸಲಾಗುತ್ತದೆ</span></li>
                </ul>
                <div className="modal-actions">
                    <button className="btn" onClick={onAccept}>Accept & Continue</button>
                </div>
            </div>
        </div>
    );
};

export default NoticeModal;

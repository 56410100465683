import React, { useState } from 'react';
import './Login.css'; // Custom CSS for styling
import accountbg from '../images/account-bg.jpg';
import axios from 'axios';

const Login = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '' // Ensure the state key is 'phoneNumber'
    });

    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [qrCode, setQrCode] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [error, setError] = useState("");

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };

    const handleOtpChange = (element, index) => {
        const value = element.value;
        const newOtp = [...otp];

        if (/^[0-9]$/.test(value) || value === "") {
            newOtp[index] = value;
            setOtp(newOtp);

            if (value !== "" && element.nextSibling) {
                element.nextSibling.focus();
            }

            if (value === "" && element.previousSibling) {
                element.previousSibling.focus();
            }
        }
    };

    const handleShowPopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://nmcnewnodeapi-f5h4bwddhacvhban.centralindia-01.azurewebsites.net/api/signup', formData);

            if (response.data.success) {
                if (response.data.isExistingUser) {
                    setQrCode(response.data.qrCodeData);
                    setOtpSent(false);
                    setError("");
                } else {
                    setOtpSent(true);
                    setError("");
                }
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setError(error.response.data.message);
            } else {
                setError('Failed to process the request. Please try again.');
            }
        }
    };

    const handleOtpSubmit = async () => {
        const enteredOtp = otp.join('');
        try {
            const response = await axios.post('https://nmcnewnodeapi-f5h4bwddhacvhban.centralindia-01.azurewebsites.net/api/validate-otp', {
                phoneNumber: formData.phoneNumber,
                enteredOtp
            });

            if (response.data.success) {
                setQrCode(response.data.qrCodeData);
                setError("");
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setError(error.response.data.message);
            } else {
                setError('Failed to validate OTP. Please try again.');
            }
        }
    };

    if (qrCode) {
        return (
            <section className="qr-section" style={{ backgroundImage: `url(${accountbg})` }}>
                <div className="container">
                    <div className="qr-code-area">
                        <h3>Please use this QR code as the confirmation of reservation</h3>
                        <img src={qrCode} alt="QR Code" />
                    </div>
                </div>
            </section>
        );
    }

    return (
        <section
            className="account-section bg_img"
            style={{ backgroundImage: `url(${accountbg})` }}
        >
            <div className="container">
                <div className="padding-top padding-bottom">
                    <div>
                        {!otpSent && !showPopup && (
                            <>
                                <div className="section-header-3">
                                    <span className="cate">REGISTER FOR</span>
                                    <h2 className="title">THE EVENT</h2>
                                </div>

                                <form className="account-form" onSubmit={handleFormSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="firstName">
                                            First Name<span>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Enter First Name"
                                            id="firstName"
                                            required
                                            value={formData.firstName}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="lastName">
                                            Last Name<span>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Enter Last Name"
                                            id="lastName"
                                            required
                                            value={formData.lastName}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">
                                            Email ID<span>*</span>
                                        </label>
                                        <input
                                            type="email"
                                            placeholder="Enter Your Email"
                                            id="email"
                                            required
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phoneNumber">
                                            Phone Number<span>*</span>
                                        </label>
                                        <input
                                            type="tel"
                                            placeholder="Enter Phone Number"
                                            id="phoneNumber" // Change the id to 'phoneNumber'
                                            required
                                            value={formData.phoneNumber}

                                            onChange={(e) => {
                                                // Allow only numbers
                                                const value = e.target.value.replace(/\D/g, "");
                                                setFormData({ ...formData, phoneNumber: value });
                                            }}
                                            maxLength={10}
                                            pattern="[0-9]*" // Ensure only numbers are accepted
                                        />
                                    </div>

                                    <div className="form-group checkgroup">
                                        <input type="checkbox" id="bal" required title="Please accept the terms and continue" />
                                        <label htmlFor="bal">
                                            I agree to the <a href="#0">Terms & Conditions</a>
                                        </label>
                                    </div>


                                    <div className="join-us-button1">
                                        <input type="submit" className="btn" value="Get OTP" />
                                    </div>
                                </form>
                            </>
                        )}

                        {otpSent && !qrCode && (
                            <div className="otp-popup">
                                <h3>OTP Authentication</h3>
                                <p>Enter the 6-digit OTP sent to your mobile number.</p>
                                <div className="otp-inputs">
                                    {otp.map((data, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            maxLength="1"
                                            className="otp-input"
                                            value={data}
                                            onChange={(e) => handleOtpChange(e.target, index)}
                                            onFocus={(e) => e.target.select()}
                                        />
                                    ))}
                                </div>
                                <button className="btn otp-submit" onClick={handleOtpSubmit}>Get QR Code</button>
                                <p className="resend-otp">
                                    Didn’t receive an OTP? <span className="resend-link">Resend</span>
                                </p>
                            </div>
                        )}

                        {error && <p className="error">{error}</p>}

                        {showPopup && (

                            <div className="popup-overlay">
                                <div className="popup-content">
                                    <span className="close-icon" onClick={handleClosePopup}>&times;</span>

                                    <h2>Privacy Policy & Terms</h2>
                                    <ul className="notice-list1">
                                        <li><span className="point-text">Only one pass would be allotted per phone number. <br />ಪ್ರತಿ ಫೋನ್ ಸಂಖ್ಯೆಗೆ ಕೇವಲ ಒಂದು ಪಾಸ್ ಅನ್ನು ಮಾತ್ರ ಹಂಚಲಾಗುತ್ತದೆ</span></li>
                                        <li><span className="point-text">Limited seats and seats will be allotted at the venue on a first-come, first-served basis. <br />ಸೀಮಿತ ಆಸನಗಳು ಮಾತ್ರ ಇರುವುದರಿಂದ, ಸ್ಥಳದಲ್ಲೆ ಆಸನಗಳನ್ನು ಮೊದಲು ಬಂದವರಿಗೆ ಆದ್ಯತೆಯ ಆಧಾರದ ಮೇಲೆ ಹಂಚಲಾಗುತ್ತದೆ</span></li>
                                        <li><span className="point-text">The pass is valid only for one day. You can enter only one day using this pass. <br />ಪಾಸ್ ಒಂದು ದಿನಕ್ಕೆ ಮಾತ್ರ ಮಾನ್ಯವಾಗಿರುತ್ತದೆ. ಈ ಪಾಸ್ ಬಳಸಿ ನೀವು ಕೇವಲ ಒಂದು ದಿನ ಮಾತ್ರ ನಮೂದಿಸಬಹುದು</span></li>
                                        <li><span className="point-text">If you exit the event during the event, the QR code will not work for re-entry. <br />ಈವೆಂಟ್ ಸಮಯದಲ್ಲಿ ನೀವು ಈವೆಂಟ್‌ನಿಂದ ನಿರ್ಗಮಿಸಿದರೆ, ಮರು-ಪ್ರವೇಶಕ್ಕಾಗಿ QR ಕೋಡ್ ಕಾರ್ಯನಿರ್ವಹಿಸುವುದಿಲ್ಲ</span></li>
                                        <li><span className="point-text">Right of admission is reserved with the management of NammaChitra. <br />ಪ್ರವೇಶದ ಹಕ್ಕನ್ನು ನಮ್ಮಚಿತ್ರದ ನಿರ್ವಹಣೆಯೊಂದಿಗೆ ಕಾಯ್ದಿರಿಸಲಾಗುತ್ತದೆ</span></li>
                                    </ul>

                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;



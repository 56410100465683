

import React, { useState, useEffect } from 'react';
import './Banner.css';
import { Link, useNavigate } from 'react-router-dom';
import NoticeModal from './NoticeModal';

const Banner = () => {
    const [isModalVisible, setModalVisible] = useState(false);
    const navigate = useNavigate();

    const handleRegisterClick = () => {
        setModalVisible(true);
    };


    const handleAccept = () => {
        setModalVisible(false);
        navigate('/login');
    };

    const handleClose = () => {
        setModalVisible(false);
    };

    useEffect(() => {
        if (isModalVisible) {
           
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isModalVisible]);

    return (
        <section className="banner-section">
            <div className="banner-bg"></div>

            {/* Conditionally render the banner content based on the modal visibility */}
            {!isModalVisible && (
                <div className="container">
                    <div className="banner-content">
                        <h1 className="title">
                            <span className="large-text">BOOK YOUR</span> <br />
                            <span className="large-text">TICKETS FOR</span> <br />

                            <div className="register-button">
                                <input
                                    type="submit"
                                    className="btn"
                                    value="REGISTER"
                                    onClick={handleRegisterClick}
                                />
                            </div>

                            <span className="highlight">SANDALWOOD BADMINTON CUP 2024</span>
                        </h1>
                    </div>
                </div>
            )}

            {/* Notice Modal */}
            <NoticeModal
                isVisible={isModalVisible}
                onClose={handleClose}
                onAccept={handleAccept}
            />
        </section>
    );
};

export default Banner;

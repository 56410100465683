import React from 'react';
import './Privacypolicy.css';
import accountbg from '../images/account-bg.jpg';

const PrivacyPolicy = () => {
    return (
        <section className="privacy-policy-section">
            <div className="privacy-policy-container">

                <h1>Privacy Policy</h1>
                <p className="para1">
                    NammaChitra ("we", "us", or "our") is a product/project of Agni Interactive Gaming Solutions and is committed to protecting the privacy of our users ("you", "your", or "user"). This Privacy Policy explains how we collect, use, and disclose personal information through our website www.nammachitra.com (the "Website").
                </p>

                <h2>Information We Collect</h2>
                <ul className="ur1">
                    <li>Contact Information: name, email address, phone number</li>
                    <li>Login Credentials: username, password (hashed for security)</li>
                    <li>Payment Information: credit card number, expiration date, CVV (processed securely through our payment gateway)</li>
                    <li>Booking Information: cinema location, movie title, showtime, number of tickets</li>
                    <li>Device Information: IP address, browser type, operating system</li>
                </ul>

                <h2>How We Use Your Information</h2>
                <ul className="ur1">
                    <li>Process and confirm bookings</li>
                    <li>Send booking confirmations and reminders</li>
                    <li>Provide customer support</li>
                    <li>Improve our services and Website</li>
                    <li>Send marketing communications (with your consent)</li>
                </ul>

                <h2>How We Share Your Information</h2>
                <ul className="ur1">
                    <li>Payment processors (for payment processing)</li>
                    <li>Cinema partners (to confirm bookings)</li>
                    <li>Service providers (for technical support)</li>
                </ul>

                <h2>Your Rights</h2>
                <ul className="ur1">
                    <li>Access and update your personal information</li>
                    <li>Request deletion of your personal information</li>
                    <li>Object to processing for marketing purposes</li>
                </ul>

                <h2>Security</h2>
                <p className="para1">
                    We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, or use.
                </p>

                <h2>Changes to This Policy</h2>
                <p className="para1">
                    We may update this Privacy Policy from time to time. We will notify you of significant changes.
                </p>

                <h2>Contact Us</h2>
                <p className="para1">
                    If you have any questions or concerns, please contact us at <a href="mailto:support@agniinteractive.com">support@agniinteractive.com</a>
                </p>
            </div>
        </section>
    );
};

export default PrivacyPolicy;
